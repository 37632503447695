import { forEach } from './util';
/* global fbq */
// Can be called with element or with number
export const selected = current => {
  const image = document.querySelector('.product-order__image');
  const price = document.querySelector('.product-order__price');
  const dollar = document.querySelector('.product-order__dollar');
  const name = document.querySelector('.product-order__subtitle');
  const button = document.querySelector('.product-order__button');
  const buttonText = button.querySelector('span');
  const stock = current.getAttribute('data-stock');
  const buttonLabel = current.getAttribute('data-label');
  const productId = current.getAttribute('data-productid');
  image.setAttribute('src', current.getAttribute('data-image'));
  price.textContent = current.getAttribute('data-price');
  dollar.textContent = current.getAttribute('data-dollar');
  name.textContent = current.getAttribute('data-name');
  if (stock === 'outofstock') {
    button.setAttribute('disabled', 'disabled');
    button.classList.add('button--disabled');
  } else {
    button.removeAttribute('disabled');
    button.classList.remove('button--disabled');
  }
  buttonText.innerText = buttonLabel;
  fbq('track', 'ViewContent', {
    content_ids: [productId],
    content_name: name,
    content_type: 'product',
    value: price,
    currency: 'EUR',
  });
};

export const enhancer = (el) => {
  const options = document.querySelectorAll('.product-order__input');
  options[0].click();
  selected(options[0]);
  forEach(options, (option) => {
    option.addEventListener('click', (e) => {
      selected(option);
    });
  });
  document.querySelector('.product-order__button').addEventListener('click', (e) => {
    fbq('track', 'AddToCart', {
      content_ids: [document.querySelector('input[name="add-to-cart"]:checked').value],
      content_type: 'product',
      value: document.querySelector('input[name="add-to-cart"]:checked').getAttribute('data-price'),
      currency: 'EUR',
    });
  });
};
